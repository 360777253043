import React, { useEffect, useState } from "react";
import { slugToTitle, replaceAll } from "../../utils/helpers";
import GatsbyLink from "../GatsbyLink";
import "./Breadcrumbs.scss";
import { useAllPageName } from "../../hooks";

export const Breadcrumbs = ({ location }) => {
  if (!location) {
    return null;
  }
  const [breadcrumbs, setBreadcrumbs] = useState();
  const allPages = useAllPageName();

  useEffect(() => {
    // split the path name apart into an array
    // removes the empty array items (usually at the start and end);
    const slugsOfPages = location.pathname.split("/").filter((n) => n);

    // goes through all the pages, filters them whether the slug is included in the slugsOfPages;
    const megaFilter = allPages.filter((page) =>
      slugsOfPages.includes(page.slug)
    );

    // sorts the pages into the same format as the pathname through the location prop
    const sort = megaFilter.sort(function (a, b) {
      return slugsOfPages.indexOf(a.slug) - slugsOfPages.indexOf(b.slug);
    });

    setBreadcrumbs(sort);
  }, []);

  return (
    <div className="breadcrumbs">
      <ul>
        {breadcrumbs &&
          Object.keys(breadcrumbs).length > 0 &&
          breadcrumbs.map((crumb, index) => {
            // if it is the last item in the array then don't have it as a link
            if (index !== breadcrumbs.length - 1) {
              return (
                <li key={index}>
                  <GatsbyLink to={crumb.path} decode={true}>
                    {crumb.title}
                  </GatsbyLink>
                </li>
              );
            } else {
              return (
                <li key={index}>
                  <span>{crumb.title}</span>
                </li>
              );
            }
          })}
      </ul>
    </div>
  );
};