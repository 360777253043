import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import { ContactBlock } from "../components/Acf/ContactBlock";
import { RenderShortcode } from "../components/RenderShortcode";
import { ImagePass } from "../components/Image/ImagePass";
import "./case-study.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { Carousel } from "../components/Carousel";
import { Breadcrumbs } from "../components/Breadcrumbs";

const CaseStudy = ({ data, location }) => {
  const { wordpressPost: caseStudy, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!caseStudy) return null;
  const { title, content, excerpt, featured_media, yoast, acf } = caseStudy;
  const swiperSettings = {
    id: "text-card-carousel",
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: false,
    clickable: "false",
    navgiation: true,
    pagination: {
      clickable: true,
    },
    centeredSlides: true,
    navigation: acf.gallery && acf.gallery.length > 1 ? true : false,
    loop: acf.gallery && acf.gallery.length > 1 ? true : false,
    breakpoints: {
      1080: {
        slidesPerView: 1.3,
      },
    },
  };

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        location={location}
      />
      <Header {...acf.caseStudyHeader} />
      <section className="case-container layout">
        <div className="case-container__breadcrumbs">
          <Breadcrumbs location={location} />
        </div>
        <div className="case-container__wrapper">
          <RenderShortcode content={content} />
        </div>
      </section>
      {acf.specificationsList && (
        <section className="case-specs layout">
          <div className="case-specs__wrapper">
            <h3 className="small-heading">Specifications</h3>
            <div className="case-specs__table">
              <table>
                <tbody>
                  <tr className="post-spec__table-head">
                    <th>Project</th>
                    <td>
                      <p>{acf.projectName}</p>
                    </td>
                  </tr>
                  {Object.keys(acf.specificationsList).length > 0 &&
                    acf.specificationsList?.map((row, index) => {
                      return (
                        <tr key={index}>
                          <th>{row.specTitle}</th>
                          <td>
                            <RenderShortcode content={row.specContent} />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
      {acf.gallery && (
        <section className="case-gallery full-bleed">
          <div className="case-gallery__wrapper">
            <h3 className="small-heading">Project Gallery</h3>
            <Carousel swiperSettings={swiperSettings}>
              {Object.keys(acf.gallery).length > 0 &&
                acf.gallery?.map((image, index) => {
                  return (
                    <div className=" layout" key={index}>
                      <div className="case-gallery__image">
                        <ImagePass src={image} lazy={false} />
                      </div>
                    </div>
                  );
                })}
            </Carousel>
          </div>
        </section>
      )}
      {acf.downloadsFiles > 0 && (
        <section className="case-downloads layout">
          <div className="case-downloads__wrapper">
            <span className="eyebrow case-downloads__eyebrow">Downloads</span>
            <h3 className="small-heading">Related PDF for download</h3>
            {Object.keys(acf.downloadsFiles).length > 0 &&
              acf.downloadsFiles?.map((downloadItem, index) => {
                const { file, linkLabel } = downloadItem;
                return (
                  <a
                    href={file.url.source_url || file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="case-downloads__button"
                    key={index}
                  >
                    {decodeEntities(linkLabel || file.filename)}
                  </a>
                );
              })}
          </div>
        </section>
      )}
      <ContactBlock {...acf.contactBlock} />
    </>
  );
};

export default Previewable(CaseStudy, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query CaseStudyPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    # categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
    #   edges {
    #     node {
    #       name
    #       slug
    #       path
    #       count
    #       wordpress_id
    #     }
    #   }
    # }
    wordpressPost: wordpressWpCaseStudies(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        projectName
        specificationsList {
          specTitle
          specContent
        }
        gallery {
          source_url
        }
        downloadsFiles {
          file {
            filename
            title
            url
          }
          linkLabel
        }
        contactBlock {
          contactHeading
          contactContent
          form
          # formId
          theme
          fullWidth
        }
        caseStudyHeader {
          eyebrow
          heading
          content
          action {
            url
            title
            target
          }
          sliderImages {
            background {
              source_url
            }
          }
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        # opengraph_image {
        #   source_url
        # }
        # twitter_image {
        #   source_url
        # }
      }
    }
  }
`;
